#loader {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  z-index: 999999999 !important;
}
.transparent-body {
  background-color: rgb(220, 217, 217);
}
@media screen and (max-width: 768px) {
  #loader {
    top: 115vh;
  }
}
ul li {
  list-style: none;
}
p,
li {
  color: var(--paragraph-color);
}
.MessageRight img,
.DirectorMessageRight img {
  width: 200px;
  height: 250px;
  object-fit: cover;
  border-radius: 8px;
}
[data-aos] {
  pointer-events: none;
}
.aos-animate {
  pointer-events: auto;
}
.aos-init[data-aos][data-aos].aos-animate {
  transform: unset;
}
body {
  -webkit-tap-highlight-color: transparent;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--secondary-bg-color) inset !important;
}
a {
  all: unset;
  cursor: pointer;
}
button {
  cursor: pointer;
}
a:hover {
  transition: all 0.3s ease-in-out;
  color: var(--fade-text-color);
}
p {
  line-height: 1.3;
  font-size: var(--desktop-paragraph-font);
}
table {
  border-collapse: collapse;
}
.capthca_box {
  width: fit-content !important;
}
@media screen and (max-width: 769px) {
  p {
    font-size: var(--phone-paragraph-font);
  }
  .capthca_box {
    width: 100px !important;
  }
}
#Heading,
#Secondary_Heading {
  display: inline-block;
}
#Heading > h1 {
  padding: 15px 0 5px 0;
  font-size: var(--secondary-desktop-heading-font);
}
#Secondary_Heading > h3 {
  padding: 15px 0 5px 0;
  font-size: var(--tertiary-tablet-heading-font);
}
@media screen and (max-width: 769px) {
  #Heading > h1 {
    font-size: var(--secondary-phone-heading-font);
  }
  #Secondary_Heading > h3 {
    font-size: var(--tertiary-phone-heading-font);
  }
}
#Heading::after {
  content: "";
  position: relative;
  display: flex;
  width: 60%;
  height: 5px;
  border-radius: 0px 0px 30px 30px;
  background-color: #ff0000;
  z-index: -1;
}
#Secondary_Heading::after {
  content: "";
  position: relative;
  display: flex;
  width: 100%;
  height: 4px;
  border-radius: 0 0 30px 30px;
  background-color: var(--tertiary-color);
  z-index: -1;
}
.HeaderImg {
  overflow: hidden;
  text-align: center;
  width: 100%;
}
/*  ==== GO TOPBTN ==== */
/*  ==== GO TOPBTN ==== */
.TopBtn {
  /* display: none; */
  padding: 7px;
  border-radius: 50%;
  position: fixed;
  bottom: 5vh;
  right: 10px;
  /* box-shadow: 1px 1px 8px var(--tertiary-color); */
  background-color: var(--secondary-color);
  color: #ffffff;
}
/* .TopBtn :hover {
	border-radius: 50%;
	transition: all 0.4s ease-in-out;
	transform: scale(0.9);
	color: var(--secondary-color);
	background-color: #ffffff;
} */
.TopBtn button {
  all: unset;
  cursor: pointer;
}
.TopBtn .Btn {
  font-size: 30px;
}
/*  ==== HEADER ==== */
/*  ==== HEADER ==== */
header {
  position: sticky;
  top: 0;
  z-index: 999999;
}
.Header {
  padding: 10px;
  background-color: var(--primary-color);
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.Header > .HeaderLeft {
  display: flex;
  align-items: center;
  gap: 20px;
}
.Header > .HeaderLeft > .vbulogo img {
  width: 90px;
}
.Header .HeaderName > h1 {
  font-size: 1.5rem;
}
.Header .HeaderName p {
  color: #ffffff;
}
.Header > .HeaderRight {
  display: flex;
  justify-content: space-around;
  gap: 40px;
}
.HeaderRight .header-icon {
  margin: 0;
  padding: 0;
  font-size: 2.3rem;
}
.HeaderRight > .phone-no,
.email-id {
  display: flex;
  align-items: center;
  gap: 10px;
}
.HeaderRight > .phone-no > .phone-no-desc,
.email-id-desc {
  display: flex;
  flex-direction: column;
}
.Header > .HeaderRight > .login-btn > input[type="button"] {
  border: none;
  outline: none;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0px 2px 2px #000000;
  font-weight: 700;
  cursor: pointer;
}
.login-btn-mobile input[type="button"] {
  border: none;
  outline: none;
  margin: 7px 0 0 7px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: #ffffff;
  box-shadow: 0px 2px 2px #000000;
  font-weight: 700;
  cursor: pointer;
  display: none;
}
@media screen and (max-width: 768px) {
  .login-btn-mobile input[type="button"] {
    display: block;
  }
}
.HeaderLeft .Menu {
  display: none;
}
/* Mobile View Header */
@media screen and (max-width: 769px) {
  .Header > .HeaderRight {
    display: none;
  }
  .Header > .HeaderLeft {
    justify-content: space-around;
    margin: 0 10px 0 0;
    column-gap: 10px;
  }
  .Header > .HeaderLeft > .vbulogo img {
    width: 50px;
  }
  .Header > .HeaderLeft > .HeaderName > h1 {
    font-size: var(--phone-paragraph-font);
  }
  .Header > .HeaderLeft > .HeaderName > p {
    margin-top: 5px;
    font-size: 11px;
  }
  .HeaderLeft .Menu {
    display: block;
    font-size: 30px;
  }
}
/* ==== NAVBAR ==== */
#navbar {
  position: sticky;
  top: 0;
}
.NavbarContainer {
  margin: 0 4rem;
  padding: 20px 30px;
  background-color: #ffffff;
  color: #000000;
  border-radius: 0 0 30px 30px;
  box-shadow: 0px 3px 8px #a29d9d;
  display: flex;
}
.NavbarContainer > ul {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.NavbarContainer .NavSubItems {
  width: 100%;
  background-color: #ffffff;
  z-index: 2;
  flex-direction: column;
}
.NavProgram,
.NavCampus,
.NavAdministration,
.NavGallery,
.NavAdmission,
.NavAlumni {
  display: none;
}

.NavbarContainer #NavItems > li {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  font-weight: 600;
}
.NavbarContainer #NavItems > a {
  color: #000000;
  font-weight: 600;
}
.NavbarContainer #NavItems > a :hover {
  color: red;
  cursor: pointer;
}
.NavbarContainer .NavItem {
  display: flex;
}
.NavbarContainer #NavItems > li:hover {
  color: red;
  cursor: pointer;
}
.active {
  border-bottom: 3px solid red;
  color: red;
}
@media screen and (max-width: 768px) {
  .active {
    border: none;
  }
}
.NavbarContainer .NavSubItems ul {
  background-color: #ffffff;
  width: 180px;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
}
.NavSubItems ul a {
  width: 100%;
  display: inline-flex;
  z-index: 2;
  color: #000000;
}
.NavSubItems > ul > a > li {
  width: 100%;
  display: inline-flex;
  padding: 8px 10px;
  color: #000000;
}
.NavSubItems a > li:hover {
  background-color: var(--tertiary-color);
  color: #ffffff;
}
#NavItems .downArrow {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-top: 3px;
}
@media screen and (max-width: 769px) {
  .NavbarContainer {
    all: unset;
  }
  .NavbarContainer > ul {
    display: none;
    flex-direction: column;
    position: fixed;
    right: 0;
    background: white;
    width: 70vw;
    padding: 10px;
    z-index: 2 !important;
    border-radius: 5px;
  }
  .NavbarContainer .menu-open {
    /* display: block; */
    transform: translateX(0%) !important;
  }
  .NavbarContainer .menu-close {
    transform: translateX(100%) !important;
  }
  .NavbarContainer #NavItems {
    transition: all 0.3s ease;
  }
  .NavbarContainer .NavItem {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  #NavItems li {
    padding: 7px 10px;
  }
  .NavSubItems {
    display: flex;
  }

  .NavbarContainer .NavSubItems ul {
    position: relative;
    margin-left: 50px;
  }
}
/*  PAGE */
.HomePageContainer,
.MembersContainer,
.DirectorMessageContainer,
.PageContainer {
  margin: 20px 60px;
}
.MessageToStudentContainer,
.LatestUpdatesContainer,
.CoursesContainer,
.GalleryCarouselContainer {
  margin: 40px 0;
}
@media screen and (max-width: 769px) {
  .HomePageContainer,
  .MembersContainer,
  .DirectorMessageContainer,
  .PageContainer {
    margin: 20px;
  }
  .MessageToStudentContainer,
  .LatestUpdatesContainer,
  .CoursesContainer,
  .GalleryCarouselContainer {
    margin: 20px 0;
  }
}
/* ### Hero Section ### */
.HeroSection {
  margin-bottom: -5px;
  margin-top: 20px;
}
.HeroSection > img {
  width: 100%;
  height: 50vh;
  object-fit: cover;
}
.NoticeScroll {
  position: relative;
  z-index: -5;
  width: 100%;
  display: flex;
  background-color: var(--secondary-color);
  color: #ffffff;
  overflow: hidden;
}

.NoticeScroll > .latestUpdates {
  align-items: center;
  display: flex;
  background-color: #000000;
  padding: 10px;
  z-index: 1;
}

.NoticeScroll .scroll {
  font-size: var(--desktop-paragraph-font);
  width: 100%;
  padding: 10px;
  color: #ffffff;
  white-space: nowrap;
  animation: marquee linear infinite;
}

.NoticeScroll .noticeItem {
  display: inline-block;
}
.latestUpdates h5 {
  width: max-content;
  font-size: var(--desktop-paragraph-font);
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@media screen and (max-width: 769px) {
  .HeroSection {
    margin: 0 0 -5px 0;
  }

  .HeroSection > img {
    width: 100%;
    height: 20vh;
  }

  .NoticeScroll {
    font-size: 10px;
  }

  .latestUpdates h5 {
    width: max-content;
    font-size: 11px;
  }

  .NoticeScroll .scroll {
    font-size: 11px;
  }
}
@media screen and (max-width: 1200px) {
  .HeroSection > img {
    height: 25vh;
  }
  .Campus .BannerImg > img {
    height: 25vh !important;
  }
  .clg-admisson img {
    height: 25vh !important;
  }
}
/* Message to Student*/
.MessageToStudent {
  display: flex;
}
.MessageLeft {
  display: flex;
  flex-direction: column;
  flex: 0.7;
}
.MessageLeft .Message {
  padding: 20px 0;
  font-size: var(--desktop-paragraph-font);
}
.Signature {
  align-self: flex-end;
  font-weight: 600;
}
.MessageRight {
  display: flex;
  justify-content: center;
  flex: 0.3;
}
@media screen and (max-width: 769px) {
  .MessageToStudent {
    display: flex;
    flex-direction: column-reverse;
    padding: 20px 0;
  }
  .MessageLeft .Message {
    font-size: var(--phone-paragraph-font);
  }
  .MessageLeft .Signature {
    font-size: var(--phone-paragraph-font);
  }
  .MessageRight {
    justify-content: flex-start;
  }
  .MessageRight img {
    height: 170px;
    width: 150px;
    object-fit: cover;
  }
}
/*Latest Updates*/
.NoticeContainer {
  width: 60%;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.NoticeTitle {
  display: flex;
  /* align-items: center; */
  font-size: var(--desktop-paragraph-font);
}
.NoticeDate span {
  font-size: var(--phone-paragraph-font);
  color: var(--fade-text-color);
}
.NoticeTitle .pin-icon,
.NoticeCardRight .download-icon {
  font-size: 25px;
  color: var(--primary-color);
  /* margin-top: 5px; */
}
.NoticeCard {
  padding: 10px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.viewAll {
  display: flex;
  justify-content: flex-end;
  font-weight: 600;
  font-size: var(--desktop-paragraph-font);
  color: var(--primary-color);
}
@media screen and (max-width: 769px) {
  .NoticeContainer {
    width: 100%;
    gap: 20px;
  }
  .NoticeTitle,
  .NoticeDate {
    font-size: var(--phone-paragraph-font);
  }
  .NoticeDate {
    font-size: var(--phone-paragraph-font);
    /* margin: 0 0 0 20px; */
  }
  .NoticeTitle .pin-icon,
  .NoticeCardRight .download-icon {
    font-size: 20px;
    color: var(--primary-color);
  }
  .viewAll {
    font-size: var(--phone-paragraph-font);
  }
}
/*  Courses */
.CoursesCardRow {
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  gap: 110px;
}
.CoursesContainer .CoursesCard {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #ffffff;
  width: max-content;
  border-radius: 10px;
  box-shadow: 3px 6px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.5s ease-in-out !important;
}
.CoursesCard:hover {
  transform: scale(1.05) !important;
}
.CoursesCardTop > img {
  border-radius: 10px 10px 0 0;
  width: 250px;
  height: 150px;
  object-fit: cover;
}
.CoursesCardMiddle {
  display: flex;
  gap: 30px;
  justify-content: space-around;
}
.CoursesCardMiddle h4 {
  font-size: var(--desktop-paragraph-font);
  font-weight: 500;
}
.CoursesCardMiddle p {
  color: var(--fade-text-color);
  font-weight: 500;
}
.CoursesCardMiddle-Left,
.CoursesCardMiddle-Right {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.CoursesCardBottom {
  display: flex;
  justify-content: center;
  padding: 10px 0 30px 0;
}
.CoursesCardBottom .Courses-btn {
  all: unset;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: #ffffff;
  padding: 8px 20px;
  cursor: pointer;
}
@media screen and (max-width: 769px) {
  .CoursesCardRow {
    flex-direction: column;
    align-items: center;
    gap: 70px;
  }
  .CoursesCardTop > img {
    width: 80vw;
  }
  .CoursesCardMiddle-Left,
  .CoursesCardMiddle-Right {
    gap: 0px;
  }
}
/*Gallery Carousel*/
.react-multi-carousel-list {
  z-index: -1 !important;
}
@media screen and (max-width: 768px) {
  .react-multi-carousel-list ul li {
    width: 150px !important;
  }
}
.GalleryCarouselCard {
  padding: 20px 0;
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.GalleryCarouselCard img {
  border-radius: 10px;
  box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.23);
  width: 250px;
  height: 250px;
  object-fit: cover;
  padding: 5px;
  background-color: #ffffff;
}
.GalleryCarouselTitle h6 {
  font-size: var(--desktop-paragraph-font);
  font-weight: 500;
}
@media screen and (max-width: 679px) {
  .GalleryCarouselCard img {
    width: 130px;
    height: 130px;
  }
  .GalleryCarouselTitle h6 {
    font-size: var(--phone-paragraph-font);
  }
}
/*Slogan*/
.SloganContainer {
  margin: 40px 150px;
  padding: 20px 0;
  box-shadow: 2px 2px 10px var(--fade-text-color);
  border-radius: 10px;
}
.SloganContainer .Slogan {
  padding: 20px 30px;
  display: flex;
  flex-direction: column-reverse;
  gap: 20px;
}
.Slogan > h4 {
  text-align: center;
  font-size: var(--tertiary-desktop-heading-font);
  margin-bottom: 0px;
}
@media screen and (max-width: 768px) {
  .SloganContainer {
    margin: 40px 0;
  }
  .Slogan > h4 {
    font-size: var(--tertiary-phone-heading-font);
  }
}
.Slogan > p {
  text-align: center;
  font-size: var(--phone-paragraph-font);
  word-spacing: 5px;
}
/*Footer*/
#Footer {
  background-color: var(--primary-color);
  /* background-color: #0f1a41; */
  color: var(--primary-bg-color);
  height: max-content;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
}
#Footer li,
#Footer p {
  color: var(--primary-bg-color);
}
.FooterContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.FooterLeft h4 {
  font-size: var(--tertiary-desktop-heading-font);
}
.FooterMiddle {
  display: flex;
  justify-content: space-around;
  gap: 100px;
}
.FooterMiddle ul {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.FooterRight {
  width: 300px;
}
.FooterCopyRight {
  padding: 20px 45px 0 45px;
}
@media screen and (max-width: 769px) {
  #Footer {
    padding: 20px;
  }
  .FooterContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .FooterContainer li {
    font-size: var(--phone-paragraph-font);
  }
  .FooterLeft {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .FooterLeft img {
    width: 80px;
  }
  .FooterLeft h4 {
    font-size: var(--tertiary-phone-heading-font);
  }
  .FooterLeft p {
    font-size: var(--phone-paragraph-font);
  }
  .FooterCopyRight {
    padding: 10px 0 0 0;
    font-size: var(--phone-paragraph-font);
  }
  .FooterMiddle {
    gap: 80px;
  }
}
/* ========== Director's Message page ==========*/
/* ========== Director's Message page ==========*/
.DirectorMessageHeader {
  display: flex;
  align-items: center;
}
.DirectorMessageFooter {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}
@media screen and (max-width: 679px) {
  .DirectorMessageHeader {
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
  }
  .DirectorMessageRight {
    padding-top: 20px;
  }
}
/* ========== Teaching page ==========*/
/* ========== Teaching page ==========*/
.MembersContainer .Members {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  row-gap: 50px;
  column-gap: 10px;
  padding: 40px 0;
}
.Members > .MemberCard {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: calc(100% / 3.9);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 10px;
  border-bottom: 7px solid var(--primary-color);
  padding: 20px;
  transition: all 0.5s ease;
}
.Members > .MemberCard:hover {
  transform: scale(0.95);
}
.Members > .MemberCard > .TopMemberCard {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.TopMemberCard > img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}
.TopMemberCard > h2 {
  font-size: var(--tertiary-desktop-heading-font);
  margin: 10px 0;
}
.TopMemberCard > h4 {
  font-size: var(--tertiary-desktop-paragraph-font);
  color: #a29d9d;
}
.TopMemberCard > p {
  font-size: var(--tertiary-desktop-paragraph-font);
  color: #a29d9d;
}
.MiddleMemberCard {
  display: flex;
  flex-direction: column;
  justify-items: center;
  gap: 5px;
  font-size: var(--tertiary-phone-heading-font);
}
.MiddleMemberCardFirstP {
  display: flex;
  align-items: flex-start;
  font-weight: 500;
}
.MiddleMemberCard .tickIcon {
  margin-right: 5px;
  color: var(--primary-bg-color);
  font-size: 15px;
  align-self: center;
}
.MiddleMemberCard tr td {
  padding: 0;
}
.Members > .MemberCard > .BottomMemberCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.BottomMemberCard .resumebtn {
  display: flex;
  align-items: center;
  color: var(--secondary-color);
  font-weight: 600;
  border: none;
  padding: 8px 20px;
  background-color: #ffffff;
  border-radius: 50px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  outline: none;
}
@media screen and (max-width: 769px) {
  .MembersContainer .Members {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .Members > .MemberCard {
    width: calc(100% / 1.2);
  }
  .TopMemberCard > h2 {
    font-size: var(--secondary-phone-heading-font);
    margin-bottom: 0;
  }
  .TopMemberCard > h4 {
    font-size: var(--tertiary-phone-paragraph-font);
  }
  .MiddleMemberCard p {
    font-size: var(--phone-paragraph-font);
  }
}
/* ###### BCA PAGE ######*/
/* ###### BCA PAGE ######*/
.CourseBanner {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}
.CourseDetailsContainer {
  padding: 20px 0;
}
.CourseTitle {
  padding-bottom: 20px;
}
.CourseDesc {
  font-size: var(--desktop-paragraph-font);
  word-spacing: 5px;
}
.CourseTable {
  padding: 20px 0;
}
.CourseDetailsContainer .CourseTable > table {
  width: 60%;
  font-size: var(--desktop-paragraph-font);
  border-collapse: collapse;
}
.CourseTable tr,
.CourseTable td {
  padding: 10px 5px;
}
.CourseTable table > tr:nth-child(odd) {
  background-color: var(--secondary-bg-color);
}
.PageContainer .SubHeading > h4 {
  margin: 1rem 0;
  font-size: var(--tertiary-tablet-heading-font);
}
.check-icon {
  font-size: 1rem;
  color: var(--primary-color);
  margin-right: 0.5rem;
}
.CourseDetailsProcess {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}
.CourseDetailsProcess ul li {
  padding: 0.5rem;
  font-size: var(--desktop-paragraph-font);
}
.CourseDetailsProcess ul > li > ul {
  padding: 0.7rem 0 0 2rem;
}
.Accordion {
  padding: 20px 0;
}
.accordion__button {
  width: auto !important;
}
.Accordion table {
  text-align: center;
  width: 100%;
}
.Accordion table th {
  padding: 13px;
  font-size: var(--desktop-paragraph-font);
  background-color: var(--primary-bg-color);
}
.Accordion table td {
  padding: 8px 0;
}
.Accordion table tr:nth-child(2n) {
  background-color: var(--secondary-bg-color);
}
@media screen and (max-width: 769px) {
  .CourseBanner > img,
  .GalleryBanner > img {
    width: 100%;
    height: 20vh;
  }
  .CourseDesc {
    font-size: var(--phone-paragraph-font);
  }
  .CourseDetailsContainer .CourseTable > table {
    width: 100%;
    border-collapse: collapse;
    font-size: var(--phone-paragraph-font);
  }
  .PageContainer .SubHeading > h4 {
    margin: 0 0 0.3rem 0;
    font-size: var(--tertiary-phone-heading-font);
  }
  .CourseDetailsProcess ul li {
    padding: 0.3rem 0.5rem;
    font-size: var(--phone-paragraph-font);
  }
  .CourseDetailsProcess ul > li > ul {
    padding: 0.3rem 0 0 1rem;
  }
  .check-icon {
    font-size: 0.8rem;
    color: var(--primary-color);
    margin-right: 0.4rem;
  }
  .CourseDetailsProcess {
    gap: 15px;
  }
  .Accordion table th {
    padding: 3px 8px;
    font-size: 13px;
  }
  .Accordion table td {
    padding: 5px;
    font-size: 11px;
  }
}
/*======ABOUT PAGE======*/
/*======ABOUT PAGE======*/
.AboutDesc {
  padding: 20px 0 0 0;
  font-size: var(--desktop-paragraph-font);
}
.AboutList {
  padding: 0 30px;
  font-size: var(--desktop-paragraph-font);
}
@media screen and (max-width: 769px) {
  .AboutDesc {
    padding: 10px 0 0 0;
    font-size: var(--phone-paragraph-font);
    word-spacing: 3px;
  }
  .AboutList {
    padding: 0 0 0 10px;
    font-size: var(--phone-paragraph-font);
    line-height: 1.5;
  }
}
/*======Contact Us Page======*/
.ContactMiddle {
  margin: 50px 0;
  display: flex;
  justify-content: space-around;
  gap: 40px;
}
.ContactMiddle .ContactUsTitle > h3 {
  font-size: var(--tertiary-desktop-heading-font);
}
.ContactMiddle .ContactMiddleLeft,
.ContactMiddle .ContactMiddleRight {
  width: calc(100% / 3);
  padding: 30px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  border-radius: 10px;
  border-bottom: 8px solid var(--primary-color);
}
.ContactMiddle .ContactUsDesc {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.ContactMiddle .DescRow {
  display: flex;
  font-size: var(--desktop-paragraph-font);
  align-items: flex-start;
}
.ContactMiddle .DescRow tr {
  display: flex;
  align-items: center;
}
.contact-icon {
  margin-right: 10px;
  font-size: 20px;
  padding: 7px;
  color: #ffffff;
  background-color: var(--primary-color);
  border-radius: 50%;
}
.ContactFooter,
.AlumniContainer,
.ResearchContainer {
  padding: 30px 15px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 10px;
  border-bottom: 8px solid var(--primary-color);
  margin: 0 calc(50% / 2);
}
.EnquiryHeading,
.AlumniHeading,
.ResearchHeading {
  display: flex;
  justify-content: center;
  padding: 10px 0 40px 0;
}
.EnquiryHeading > div,
.AlumniHeading > div,
.ResearchHeading > div {
  display: flex;
  flex-direction: column;
  width: max-content;
  align-items: center;
  text-align: center;
  font-size: var(--tertiary-desktop-heading-font);
}
@media screen and (max-width: 768px) {
  .EnquiryHeading > div,
  .AlumniHeading > div,
  .ResearchHeading > div {
    font-size: var(--tertiary-phone-heading-font);
  }
}
.border-bottom {
  width: 70%;
  height: 4px;
  border-radius: 10px;
  border-bottom: 4px solid red;
}
.ContactFooter .EnquiryForm > form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.EnquiryForm .FirstRow,
.EnquiryForm .SecondRow {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
input[type="text"],
input[type="email"],
input[type="number"],
input[type="date"],
select {
  padding: 10px;
  width: 100%;
  border-radius: 8px;
  outline: none;
  border: 1px solid #dfdfdf;
}
.ContactFooter textarea {
  padding: 10px;
  width: 95%;
  height: 100px;
  border-radius: 8px;
  outline: none;
  border: 1px solid #dfdfdf;
  resize: none;
}
input[type="submit"] {
  all: unset;
  width: max-content;
  align-self: center;
  padding: 10px 40px;
  font-weight: 700;
  border-radius: 8px;
  background-color: var(--primary-color);
  color: #ffffff;
  cursor: pointer;
}
input[type="submit"]:hover {
  transition: all 0.5s ease-in-out;
  background: var(--secondary-color);
}
@media screen and (max-width: 769px) {
  .ContactHeader > img,
  .AdmissionHeader > img {
    width: 90vw;
    height: 20vh;
  }
  .ContactMiddle {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ContactMiddle .ContactUsTitle h3 {
    font-size: var(--secondary-phone-heading-font);
  }
  .ContactMiddle .ContactMiddleLeft,
  .ContactMiddle .ContactMiddleRight {
    width: 70vw;
  }

  .ContactMiddle .DescRow {
    font-size: var(--phone-paragraph-font);
  }
  .contact-icon {
    font-size: 15px;
  }
  .ContactFooter {
    margin: 0;
  }
}
/*====== canteen ======*/
/*====== canteen ======*/
.Campus .BannerImg > img {
  width: 100%;
  height: 50vh;
  object-fit: cover;
}
.Campus .Desc {
  display: flex;
  justify-content: space-around;
  gap: 50px;
}
.Campus .Desc .DescParagraph p {
  font-size: var(--desktop-paragraph-font);
  padding: 20px 0;
}
.Campus .Desc > .DescImg > img {
  width: 450px;
  height: 250px;
  object-fit: cover;
}
.Campus .PhotosSection {
  margin: 20px 0;
}
.Campus .PhotosSection .PhotoContainer {
  display: flex;
  flex-direction: column;
  row-gap: 50px;
}
.Campus .PhotosSection .PhotoRow {
  display: flex;
  justify-content: space-between;
  column-gap: 50px;
}
.PhotoContainer {
  padding: 20px 0;
}
.PhotoContainer :nth-child(2n) {
  display: flex;
  flex-direction: row-reverse;
}
.PhotoContainer :nth-child(2n + 1) {
  width: 100%;
  display: block;
}
.PhotoRow .Img1 {
  flex: 0.6;
}
.PhotoRow .Img2 {
  flex: 0.4;
}
.PhotoRow img {
  border-radius: 10px;
  width: 100%;
  height: 300px;
  object-fit: cover;
}
@media screen and (max-width: 769px) {
  .Campus .BannerImg > img {
    width: 100%;
    height: 20vh;
  }
  .Campus .Desc {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
  .Campus .Desc .DescParagraph p {
    font-size: var(--phone-paragraph-font);
  }
  .Campus .Desc > .DescImg > img {
    display: none;
  }
  .Campus .PhotosSection .PhotoContainer {
    row-gap: 10px;
  }
  .PhotoRow img {
    width: 100%;
    height: 15vh;
  }
  .Campus .PhotosSection .PhotoRow {
    column-gap: 10px;
  }
}
/*====== ADMISSION PROCESS PAGE ======*/
/*====== ADMISSION PROCESS PAGE ======*/
.AdmissionProcessDesc {
  font-size: var(--desktop-paragraph-font);
  padding: 10px 0 20px 0;
}
.BCA_MCA_Container > .CourseContainer > .Course {
  display: flex;
  justify-content: space-around;
  gap: 40px;
}
.BCA_MCA_Container > .CourseContainer {
  margin: 30px 0 50px 0;
  padding: 20px;
  border-radius: 10px;
  border-left: 8px solid var(--primary-color);
  box-shadow: 3px 0 10px rgba(0, 0, 0, 0.16);
}
.BCA_MCA_Container > .CourseContainer > .course-title {
  margin-bottom: 5px;
  font-size: var(--desktop-paragraph-font);
}
.BCA_MCA_Container > .CourseContainer > .Course > .CourseDesc {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.BCA_MCA_Container > .CourseContainer .ReadMore > a {
  color: var(--primary-color);
  font-weight: 700;
}
.BCA_MCA_Container .Course .CourseImg > img {
  width: 350px;
  border-radius: 10px;
}
.AdmissionProcessAccordion {
  margin-bottom: 20px;
}
@media screen and (max-width: 769px) {
  .AdmissionProcessDesc {
    font-size: var(--phone-paragraph-font);
  }
  .BCA_MCA_Container > .CourseContainer > .Course {
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
  }
  .BCA_MCA_Container > .CourseContainer .CourseDesc > p {
    font-size: var(--phone-paragraph-font);
  }
  .BCA_MCA_Container .Course .CourseImg > img {
    width: 100%;
  }
}
/*====== FEE STURCTURE ======*/
/*====== FEE STRUCTURE  ======*/
.FeeStructureSection {
  display: flex;
  justify-content: space-around;
  gap: 40px;
  margin: 15px 30px;
}
.FeeStructureSection p {
  font-size: var(--desktop-paragraph-font);
}
.FeeStructureSection img {
  width: 350px;
}
.FeeStructureTable > table {
  width: -webkit-fill-available;
  margin: 30px;
  border-spacing: 0;
  border: 1px solid var(--secondary-bg-color);
}
.FeeStructureTable article {
  margin: 30px;
}
.FeeStructureTable table thead {
  background-color: var(--primary-bg-color);
  text-align: left;
  font-size: var(--desktop-paragraph-font);
}
.FeeStructureTable table th {
  padding: 8px 10px;
}
.FeeStructureTable table td {
  padding: 8px 10px;
}
.FeeStructureTable table tfoot {
  border-top: 1px solid var(--secondary-bg-color);
  font-weight: 650;
}
.clg-admisson img {
  height: 50vh;
  width: 100%;
}
@media screen and (max-width: 769px) {
  .FeeStructureSection {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 40px;
    margin: 10px 0;
  }
  .FeeStructureSection img {
    display: none;
  }
  .FeeStructureSection p {
    font-size: var(--phone-paragraph-font);
  }
  .FeeStructureTable > table,
  .FeeStructureTable article {
    margin: 20px 15px;
  }
  .FeeStructureTable table thead {
    font-size: var(--phone-paragraph-font);
  }
  .FeeStructureTable table td {
    font-size: 12px;
  }
  .clg-admisson img {
    height: 20vh;
  }
}
/*====== GALLERY ======*/
/*====== GALLERY ======*/
.GalleryContainer .GalleryCardContainer {
  margin: 10px 0 40px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0px;
}
.GalleryContainer .GalleryRow {
  margin: 20px 0;
}
.GalleryTitle {
  display: flex;
  justify-content: space-between;
}
.GalleryTitle h5 a {
  font-size: var(--tablet-paragraph-font);
  color: var(--primary-color);
}
.GalleryContainer h5 {
  font-size: var(--tertiary-desktop-heading-font);
}
.GalleryCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: calc(100% / 6);
}
.GalleryCard > img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
@media screen and (max-width: 769px) {
  .GalleryContainer .GalleryCardContainer {
    margin: 10px 0 30px 0;
    gap: 30px;
  }
  .GalleryContainer h5 {
    font-size: var(--tertiary-phone-heading-font);
  }
  .GalleryCard {
    gap: 10px;
    width: calc(100% / 2.3);
  }
  .GalleryCard > img {
    width: 100%;
    height: 150px;
  }
}
/*======ALUMNI PAGE======*/
/*======ALUMNI PAGE======*/
.AlumniContainer,
.ResearchContainer {
  margin: 50px 150px;
}
.AlumniForm,
.ResearchForm {
  display: flex;
  flex-direction: column;
  color: var(--fade-text-color);
}
.AlumniForm form,
.ResearchForm form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.AlumniForm .TopRow {
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  align-items: center;
}
.AlumniForm .TopRow .nameEmail {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
#file {
  display: none;
}
.filebox {
  display: inline-flex;
  flex: 0.1;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  width: 100px;
  height: 120px;
  border-radius: 10px;
}
.nameEmail {
  flex: 0.9;
}
.AlumniForm input[type="text"],
.AlumniForm input[type="email"],
.AlumniForm input[type="date"],
.AlumniForm input[type="number"] {
  width: -webkit-fill-available;
}
.AlumniForm .Row,
.ResearchForm .Row {
  display: flex;
  margin: 10px 0;
  justify-content: space-between;
  gap: 50px;
}
.AlumniForm .Row .RowLeft,
.ResearchForm .Row .RowLeft {
  display: flex;
  gap: 10px;
  flex: 0.5;
}
.AlumniForm .Row .RowRight,
.ResearchForm .Row .RowRight {
  display: flex;
  flex: 0.5;
}
.AlumniForm #file-img {
  width: 140px;
  height: 120px;
  z-index: 0;
  object-fit: cover;
  position: absolute;
}
.ResearchDesc ol {
  font-size: var(--desktop-paragraph-font);
  line-height: 1.5;
}
/*Alumni List*/
.AlumniTable table {
  width: 100%;
  text-align: center;
  border: 1px solid var(--fade-text-color);
  border-spacing: 0;
}
.AlumniTable thead {
  background-color: var(--primary-bg-color);
}
.AlumniTable th {
  padding: 15px 0;
  font-size: var(--desktop-paragraph-font);
}
.AlumniTable td {
  padding: 10px 0;
}
.AlumniTable img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
}
@media screen and (max-width: 769px) {
  .ResearchHeader > img,
  .AlumniHeader > img {
    width: 100%;
    height: 20vh;
  }
  .AlumniContainer,
  .ResearchContainer {
    margin: 20px 0;
  }
  .AlumniForm form,
  .ResearchForm form {
    gap: 0;
  }
  .ResearchDesc ol {
    font-size: var(--phone-paragraph-font);
  }
  .AlumniForm .TopRow {
    display: flex;
    gap: 0px;
    margin-bottom: 10px;
  }
  .AlumniForm .TopRow .nameEmail {
    width: 150px;
    gap: 20px;
  }
  .AlumniForm .Row,
  .ResearchForm .Row {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    gap: 20px;
  }
  .filebox {
    width: 80px;
    height: 100px;
  }
  .AlumniForm #file-img {
    width: 120px;
    height: 100px;
  }
  /*Alumni List*/
  .AlumniTable th {
    padding: 5px;
    font-size: 10px;
  }
  .AlumniTable td {
    padding: 5px 0;
    font-size: 10px;
  }
  .AlumniTable img {
    width: 25px;
    height: 25px;
  }
}
#reload_href {
  color: inherit !important;
  margin-left: 20px;
}
