body {
  margin: 0;
  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FBFBFB;
}
h1,h2,h3,h4,h5,h6,p,ul,li,a{
  margin:0;
  padding:0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  /* --primary-color:#1b327f; */
  --primary-color:#0f1a41;
  --secondary-color:#02215b;
  --tertiary-color:#5f64ae;


  --primary-bg-color:#c5cdeb;
  --secondary-bg-color:#e8ecf9;
  --fade-text-color:#8d8d8d;

  --paragraph-color:#474747;
  




  /*Font size desktop*/
  --primary-desktop-heading-font:2.5rem;
  --secondary-desktop-heading-font:2rem;
  --tertiary-desktop-heading-font:1.5rem;

  --desktop-paragraph-font:1.1rem;


  /*Font size tablet*/
  --primary-tablet-heading-font:2rem;
  --secondary-tablet-heading-font:1.5rem;
  --tertiary-tablet-heading-font:1.3rem;

  --tablet-paragraph-font:1rem;


  /*Font size phone*/
  --primary-phone-heading-font:1.7rem;
  --secondary-phone-heading-font:1.3rem;
  --tertiary-phone-heading-font:1.2rem;
  

  --phone-paragraph-font:0.9rem;
}
